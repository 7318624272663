import { RouterModule, Routes } from '@angular/router';

import { RegisterComponent } from 'src/app/features/public/register/register.component';
import { RecoverPasswordComponent } from '../../features/public/recover-password/recover-password.component';
import { ResetPasswordComponent } from '../../features/public/reset-password/reset-password.component';
import { LoginComponent } from './../../features/public/login/login.component';

export const PUBLIC_ROUTES: Routes =
    [
        { path: '', redirectTo: 'login', pathMatch: 'full' },
        { path: 'login', component: LoginComponent },
        { path: 'recoverPassword', component: RecoverPasswordComponent },
        { path: 'resetPassword', component: ResetPasswordComponent },
        { path: 'registration', component: RegisterComponent }
    ];
