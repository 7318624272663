import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { SelectItem } from 'primeng/api';

import { Router } from '@angular/router';
import dropDownOptions from '../drop-down';

@Component({
  selector: 'app-main-module-selector',
  templateUrl: './main-module-selector.component.html',
  styleUrls: ['./main-module-selector.component.scss'],
})
export class MainModuleSelectorComponent implements OnInit {
  @Input() public allowedModules: { [key: string]: boolean };
  @Output()
  public dropdownValueChanged: EventEmitter<string> = new EventEmitter();
  public listItems: SelectItem[];
  public form: FormGroup;

  constructor(private forms: FormBuilder, private router: Router) {}

  public ngOnInit() {
    this.listItems = this.setListItemsPermissions(
      dropDownOptions,
      this.allowedModules
    );
    this.setFormValue();
  }

  private setListItemsPermissions(
    items: SelectItem[],
    allowedModules: { [key: string]: boolean }
  ): SelectItem[] {
    return items.reduce(
      (selectItemsList: SelectItem[], selectItem: SelectItem) => {
        return [
          ...selectItemsList,
          {
            ...selectItem,
            disabled: !allowedModules[selectItem.value],
          },
        ];
      },
      []
    );
  }

  private setFormValue(): void {
    this.form = this.forms.group({
      selectedModule: this.getCurrentModule(),
    });

    this.form.valueChanges.subscribe(({ selectedModule }) =>
      this.dropdownValueChanged.emit(selectedModule)
    );
  }

  public valueChange(selectedModule: string): void {
    this.dropdownValueChanged.emit(selectedModule);
  }

  private getCurrentModule() {
    const [modulePath] = this.router.url.split('/').filter((x) => x !== '');
    return `/${modulePath}`;
  }
}
