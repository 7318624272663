import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';

import { AppSetting } from '@lbmx/types';
import { EnvService } from '../services/env.service';

@Injectable()
export class ConfigProvider {
  //#region Properties

  private appSetting: AppSetting = null;

  //#endregion

  constructor(private http: HttpClient, private env: EnvService) {}

  //#region Methods
  get AppSetting(): AppSetting {
    return this.appSetting;
  }

  public load() {
    return new Promise((resolve, reject) => {
      let jsonFile;

      if (this.env.Name === 'debug') {
        jsonFile = `assets/config/config.debug.json`;
      } else {
        jsonFile = `assets/config/config.json`;
      }

      this.http
        .get(jsonFile)
        .pipe(map((data) => data as AppSetting))
        .subscribe((data) => {
          this.appSetting = data;
          resolve(true);
        });
    });
  }

  //#endregion
}
