import { toolKeys } from '@lbmx/types';
export const routePermissions = {
  PIMModule: toolKeys.PIM,
  userProfile: toolKeys.AUTHENTICATED,
  login: toolKeys.AUTHENTICATED,
  AdministrationModule: toolKeys.ADMINISTRATION,
  PIMModule_products: toolKeys.PIM_PRODUCTS,
  PIMModule_exports: toolKeys.PIM,
  PIMModule_imports: toolKeys.PIM_IMPORT,
  'PIMModule_products_product-search': toolKeys.PIM_PRODUCTS,
  'PIMModule_products_config-product-field': toolKeys.PIM_PRODUCTS,
  'PIMModule_products_supplier-update-profile': toolKeys.PIM_PRODUCTS,
  'PIMModule_products_pending-products': toolKeys.PIM_PRODUCTS,
  'PIMModule_products_product-details': toolKeys.PIM_PRODUCTS,
  'PIMModule_imports_import-assets': toolKeys.PIM,
  'PIMModule_imports_import-products': toolKeys.PIM_IMPORT_LAYOUTS,
  PIMModule_analytics: toolKeys.PIM,
  'PIMModule_analytics_config-product-field': toolKeys.PIM,
  'PIMModule_imports_import-products_select-file': toolKeys.PIM_IMPORT_LAYOUTS,
  'PIMModule_imports_import-products_column-wizard':
    toolKeys.PIM_IMPORT_LAYOUTS,
  'PIMModule_imports_import-products_layout-manager':
    toolKeys.PIM_IMPORT_LAYOUTS,
  'PIMModule_imports_import-products_email-notify': toolKeys.PIM_IMPORT_LAYOUTS,
  'PIMModule_imports_import-products_finish': toolKeys.PIM_IMPORT_LAYOUTS,
  MarketplacePartnersModule: toolKeys.MPP,
  MarketplacePartnersModule_group: toolKeys.MPP_GROUP,
  MarketplacePartnersModule_group_new: toolKeys.MPP_GROUP_MAINTENANCE,
  MarketplacePartnersModule_group_edit: toolKeys.MPP_GROUP_MAINTENANCE,
  MarketplacePartnersModule_member: toolKeys.MPP_MEMBER,
  MarketplacePartnersModule_member_new: toolKeys.MPP_MEMBER_MAINTENANCE,
  MarketplacePartnersModule_member_edit: toolKeys.MPP_MEMBER_MAINTENANCE,
  MarketplacePartnersModule_supplier: toolKeys.MPP_SUPPLIER,
  MarketplacePartnersModule_supplier_new: toolKeys.MPP_SUPPLIER_MAINTENANCE,
  MarketplacePartnersModule_supplier_edit: toolKeys.MPP_SUPPLIER_MAINTENANCE,
  MarketplacePartnersModule_company: toolKeys.MPP_SUPPLIER,
  MarketplacePartnersModule_company_edit: toolKeys.MPP_SUPPLIER,
  MarketplacePartnersModule_requests: toolKeys.MPP_REQUEST,
};
