export const isAuthorized = (
  toolKeys: number[],
  permissionItems: { [key: string]: number },
  url: string
): boolean => {
  return toolKeys.length > 0
    ? Object.entries(permissionItems).reduce(
        (permissions, [name, toolKey]: [string, number]) => {
          return { ...permissions, [name]: toolKeys.includes(toolKey) };
        },
        { '/PlaygroundModule': true, '/home': true, '/default': true }
      )[`/${url.split('/')[1]}`]
    : false;
};
