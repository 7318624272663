import { Component, OnDestroy, OnInit } from '@angular/core';
import { IAccount } from '@lbmx/marketplace-partners/lib/models/account.models';
import { LoggerService } from '@lbmx/phoenix-lib-core';
import {
  SpinnerService,
  ToastrNotificationService,
} from '@lbmx/phoenix-lib-utils';
import { TranslocoService } from '@ngneat/transloco';
import * as i18nIsoCountries from 'i18n-iso-countries';
import { Observable, of } from 'rxjs';
import { finalize, map, tap } from 'rxjs/operators';
import { IConfigCountry } from 'src/app/dtos/registration/registration.dto';
import { IUserProfileForm } from 'src/app/dtos/userProfile/userProfile.dto';
import { UserProfileService } from '../../../services/user-profile/user-profile.service';
@Component({
  selector: 'app-user-profile-container',
  template: `<app-userprofile
    [fetching]="fetching"
    [account]="account$ | async"
    [countries]="countries$ | async"
    [userProfileDetails]="userProfileDetails$ | async"
    [timeZoneTypes]="timeZoneCodes"
    [languageTypes]="languageCodes"
    [DTFormat]="dateFormatTypes"
    (updatedProfileInfo)="handleSave($event)"
  ></app-userprofile>`,
  styleUrls: [],
})
export class UserProfileContainerComponent implements OnInit, OnDestroy {
  public userProfileDetails$: Observable<any> = of({
    firstName: '',
    lastName: '',
    userName: '',
    phone: '',
    phoneExtension: '',
    phoneCountry: '',
    language: '',
    timeZone: '',
    dateFormat: 0,
  });
  public account$: Observable<IAccount>;
  public countries$: Observable<any[]>;
  public dateFormatTypes = [];
  public timeZoneCodes = [];
  public languageCodes = [];
  public timeZoneTypes$: Observable<any[]>;
  public fetching = false;

  constructor(
    private loggerService: LoggerService,
    private toastSrv: ToastrNotificationService,
    private spinnerService: SpinnerService,
    private userProfileService: UserProfileService,
    private translocoService: TranslocoService
  ) {}

  public async ngOnInit() {
    this.spinnerService.on();
    this.fetching = true;
    this.account$ = this.userProfileService.getAccount();
    this.countries$ = this.userProfileService.getCountries().pipe(
      map((countries: IConfigCountry[]) =>
        countries.map((country) => {
          return {
            label: `${country.countryName} (${i18nIsoCountries.alpha3ToAlpha2(
              country.countryCode
            )})`,
            value: i18nIsoCountries.alpha3ToAlpha2(country.countryCode),
          };
        })
      )
    );
    this.userProfileDetails$ = this.userProfileService.getUserProfile().pipe(
      tap((response) => {
        this.loggerService.info(
          'getUserProfileResponse',
          'UserProfileService.getUserProfile',
          JSON.stringify(response)
        );
      }),
      map((response) => {
        return {
          firstName: response.userDetails.firstName,
          lastName: response.userDetails.lastName,
          userName: response.userDetails.username,
          phoneCountry: response.userDetails.phoneCountry,
          phone: response.userDetails.phone,
          phoneExtension: response.userDetails.phoneExtension,
          language: response.userDetails.languageCode,
          timeZone: response.userDetails.timeZoneCode,
          dateFormat: response.userDetails.dateTypeKey,
        };
      }),
      finalize(() => {
        this.fetching = false;
        this.spinnerService.off();
      })
    );

    /* Get list of DateFormat Types */
    this.dateFormatTypes = await this.userProfileService
      .getdateFormatTypes()
      .toPromise()
      .then((res) => {
        if (res.dateFormatTypeResponse != null) {
          return res.dateFormatTypeResponse.map((a) => {
            return { value: a.dateTypeKey, label: a.description };
          });
        }
      });

    /* Get list of Language Types */
    this.languageCodes = await this.userProfileService
      .getLanguageTypes()
      .toPromise()
      .then((res) => {
        if (res.languageTypes != null) {
          return res.languageTypes.map((a) => {
            return { value: a.languageCode, label: a.languageDescription };
          });
        }
      });
    /* Get list of Timezone Types */
    this.timeZoneCodes = await this.userProfileService
      .getTimezoneTypes()
      .toPromise()
      .then((res) => {
        if (res.timeZoneTypeResponse != null) {
          return res.timeZoneTypeResponse.map((a) => {
            return { value: a.timeZoneCode, label: a.timeZoneDescription };
          });
        }
      });
  }

  public ngOnDestroy() {
    this.spinnerService.off();
  }
  public handleSave(data) {
    this.spinnerService.on();
    /* Eliminate null values before updateProfile endpoint call  */
    const request = this.filterEmptyFields(data) as IUserProfileForm;
    if (request.confirmPassword) {
      delete request.confirmPassword;
    }

    this.userProfileService
      .updateUserProfile(request)
      .subscribe(
        (response) => {
          this.loggerService.info(
            'UpdateUserResponse',
            'UserProfileService.updateUserProfile',
            JSON.stringify(response)
          );
          if (response.status && response.status === 'Success') {
            this.toastSrv.successNotify(
              this.translocoService.translate('COMMON.CHANGES_SAVED')
            );
            this.translocoService.setActiveLang(request.languageCode);
          }
        },
        (error) => {
          this.loggerService.error(
            'UpdateUserResponse',
            'UserProfileService.updateUserProfile',
            JSON.stringify(error)
          );
        }
      )
      .add(() => {
        this.spinnerService.off();
      });
  }
  /** Function to filter null values from userProfileForm */
  private filterEmptyFields(data) {
    return Object.entries(data).reduce((prev, [field, value]) => {
      if (value !== '' && value !== null) {
        return { ...prev, [field]: value };
      } else {
        return prev;
      }
    }, {});
  }
}
