import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { actions, selectors } from '@lbmx/app-state';
import { select, Store } from '@ngrx/store';

import { AuthenticateService } from '@lbmx/phoenix-lib-auth';
import { LoggerService } from '@lbmx/phoenix-lib-core';
import { ToastrNotificationService } from '@lbmx/phoenix-lib-utils';

@Component({
  selector: 'app-main-header',
  templateUrl: './main-header.component.html',
  styleUrls: ['./main-header.component.scss'],
})
export class MainHeaderComponent implements OnInit {
  //#region Properties

  public userName = '';
  public companyName = '';

  //#endregion

  constructor(
    private router: Router,
    private toastrNotificationService: ToastrNotificationService,
    private authenticateService: AuthenticateService,
    private loggerService: LoggerService,
    private store: Store
  ) {}

  public ngOnInit() {
    this.store.pipe(select(selectors.userProfile)).subscribe((res) => {
      this.userName = res.userInfo.FullName;
      this.companyName = res.userInfo.CompanyName;
    });
  }

  //#region Methods

  public logOut() {
    this.authenticateService.logOut(true);
    this.store.dispatch(actions.unloadUserProfile());

    this.toastrNotificationService.successNotify(
      'User successfully logged off.'
    );

    this.loggerService.info(
      'MainHeaderComponent.logOut',
      'User successfully logged off: **Replace by Username**'
    );

    this.router.navigate(['/login']);
  }

  //#endregion
}
