import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { ErrorService } from './error.service';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {

  constructor(
    private errorHandler: ErrorService
    ) {}

  /**
   * @todo
   * Decide what to do next before and after the interceptor handles
   * the response and the request
   * @remarks
   * Any call made is first intercepted and if any errors occur, it
   * is passed onto the Error service to handle it accordingly
   * @returns
   * an observable that emits no items to the observer
   * and immediately emits an error notification
   */
  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // tslint:disable-next-line: no-empty
    return next.handle(request)
      .pipe(
        // Retry on failure
        retry(3),
        // Handle errors
        catchError((err: HttpErrorResponse) => {
          return this.errorHandler.handleHttpErrorResponse(err);
        }
        )

      );
  }

}
