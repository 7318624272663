import { Component, OnInit } from '@angular/core';

@Component
({
    selector: 'app-public-layout-component',
    templateUrl: './public.component.html'
})
export class PublicComponent implements OnInit {
    //#region Properties

    public loggedUserFullName = '';

    //#endregion

    constructor() {
        /** empty block */
    }

    public ngOnInit() {
        /** empty block */
    }
}
