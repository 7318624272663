import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SpinnerService } from '@lbmx/phoenix-lib-utils';
import { TranslocoService } from '@ngneat/transloco';
import { throwError } from 'rxjs';
import { AppError } from './app.error.helper.service';

export class HttpError {
  public message: string;
}

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  constructor(
    private translocoSrv?: TranslocoService,
    private appError?: AppError,
    private router?: Router,
    private spinnerSrv?: SpinnerService
  ) {}
  //#region Methods
  /**
   * @remarks
   * Checks for different error codes and creates an Observable<never>
   * and immediately emits an error notification.
   * The error is then passed directly to the AppError. The AppError
   * then calls the log function to log the error and show toast notification.
   *
   * At minimum every kind of error will be logged
   * @param error : actual error content (i.e. Promise Reject)
   * @returns
   * an Observable<never>
   */
  public handleHttpErrorResponse(error: HttpErrorResponse) {
    this.spinnerSrv.hide();

    // Server side error
    this.appError.log(error, error.message);
    if (
      !error.error ||
      (error.error &&
        (error.error.errorCode === 2 || error.error.errorCode === 1))
    ) {
      this.appError.showErrorMsg(
        this.translocoSrv.translate('COMMON.UNKNOWN_ERROR')
      );
    }

    return throwError(error);
  }
  //#endregion
}
