export default [
  {
    label: 'NAV.PRODUCT_INFO_MANAGEMENT',
    value: '/PIMModule',
    disabled: true,
  },
  {
    label: 'NAV.MARKETPLACE',
    value: '/MarketplaceModule',
    disabled: true,
  },
  {
    label: 'NAV.MARKETPLACE_PARTNERS',
    value: '/MarketplacePartnersModule',
    disabled: true,
  },
  {
    label: 'NAV.PLAYGROUND_DROPDOWN',
    value: '/PlaygroundModule',
    disabled: true,
  },
  {
    label: 'NAV.ADMINISTRATION_DROPDOWN',
    value: '/AdministrationModule',
    disabled: true,
  },
];
