import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// Public
import { PublicComponent } from './layouts/public/public.component';
import { PUBLIC_ROUTES } from './layouts/public/public.routes';

// Secure
import { SecureComponent } from './layouts/secure/secure.component';
import { SECURE_ROUTES } from './layouts/secure/secure.routes';

import { AuthGuardService } from './services/authGuard/authGuard.service';

const appRoutes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
    data: { breadcrumb: null },
  },
  {
    path: '',
    component: PublicComponent,
    data: { title: 'Public Views', breadcrumb: null },
    children: PUBLIC_ROUTES,
  },
  {
    path: '',
    component: SecureComponent,
    data: { title: 'Secure Views', breadcrumb: null },
    children: SECURE_ROUTES,
    canActivateChild: [AuthGuardService],
  },
  { path: '**', redirectTo: 'home', data: { breadcrumb: null } },
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, { enableTracing: false, useHash: false }),
  ],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
