import { Routes } from '@angular/router';

import { DefaultRouteService } from 'src/app/services/defaultRoute/default-route.service';
import { HomeComponent } from './../../features/secure/home/home.component';
import { UserProfileContainerComponent } from './../../features/secure/userprofile/user-profile-container';
export const SECURE_ROUTES: Routes = [
  {
    path: 'default',
    canActivate: [DefaultRouteService],
    component: HomeComponent,
  },
  {
    path: 'home',
    data: { breadcrumb: 'Home' },
    component: HomeComponent,
  },
  {
    path: 'userProfile',
    data: { breadcrumb: 'Home : User Profile' },
    component: UserProfileContainerComponent,
  },

  {
    path: 'PlaygroundModule',
    data: { breadcrumb: 'playgroundModule' },
    loadChildren:
      './wrapper-modules/playground-wrapper.module#PlaygroundWrapperModule',
  },
  {
    path: 'PIMModule',
    data: { breadcrumb: null },
    loadChildren: './wrapper-modules/pim-wrapper.module#PimWrapperModule',
  },
  {
    path: 'AdministrationModule',
    loadChildren:
      './wrapper-modules/administration-wrapper.module#AdministrationWrapperModule',
  },
  {
    path: 'MarketplacePartnersModule',
    data: { breadcrumb: 'Partners' },
    loadChildren:
      './wrapper-modules/marketplace-partners-wrapper.module#MarketplacePartnersWrapperModule',
  },
  {
    path: 'MarketplaceModule',
    data: { breadcrumb: 'Documents' },
    loadChildren:
      './wrapper-modules/marketplace-wrapper.module#MarketplaceWrapperModule',
  },
];
