import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

import { Observable } from 'rxjs';

import { AuthenticateService } from '@lbmx/phoenix-lib-auth';

@Injectable()
export class AuthGuardChildService implements CanActivateChild {

    constructor(
        private authenticateService: AuthenticateService,
        private router: Router
    ) { }

    //#region Methods

    public canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot):
        boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        return this.checkLogin(state.url);
    }

    //#endregion

    //#region Helpers

    private checkLogin(url: string): boolean {

        if (this.authenticateService.hasValidAccessToken()) {

            return true;
        }
        this.router.navigate(['/login']);

        return false;
    }

    //#endregion
}
