import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IAccount } from '@lbmx/marketplace-partners/lib/models/account.models';
import { ValidationService } from '@lbmx/phoenix-lib-utils';

import { TranslocoService } from '@ngneat/transloco';
import * as i18nIsoCountries from 'i18n-iso-countries';
import { AsYouType, CountryCode, parsePhoneNumber } from 'libphonenumber-js';
import { IUserProfileForm } from 'src/app/dtos/userProfile/userProfile.dto';
@Component({
  selector: 'app-userprofile',
  templateUrl: './userprofile.component.html',
  styleUrls: ['./userprofile.component.scss'],
})
export class UserprofileComponent {
  @Input('account') set accountSetter(account: IAccount) {
    this.account = account;
  }
  @Input('countries') set countriesSetter(countries: any[]) {
    this.countries = countries;
  }
  @Input('userProfileDetails') set userProfileDetailsSetter(
    userProfileDetails: IUserProfileForm
  ) {
    this.userProfileDetails = userProfileDetails;
    this.initializeForm();
    this.formatCountryCodeForDropdown();
    this.formatPhoneToInternational();
  }
  @Input() public DTFormat;
  @Input() public fetching = false;
  @Input() public languageTypes;
  @Input() public timeZoneTypes;
  @Output() public updatedProfileInfo = new EventEmitter<any>();
  public userProfileForm: FormGroup;
  public phoneForm: FormGroup;
  public showPasswordEdit = false;
  public passwordEmpty = true;
  public countries = [];
  public account: IAccount;
  public message = {
    confirmPassword: () =>
      this.translocoService.translate('COMMON.PASSWORD_MUST_MATCH'),
  };
  public extension = {
    extension: () => 'Extension must be digits (maximum of 10 digits)',
  };
  public userProfileDetails: IUserProfileForm;
  public phoneNumber = new AsYouType();

  constructor(
    private formBuilder: FormBuilder,
    private validationService: ValidationService,
    private translocoService: TranslocoService
  ) {}

  /*Handling Password Checkbox  Click */
  public handleUpdatePassword(status) {
    this.showPasswordEdit =
      status.checked && status.checked === true ? true : false;
  }

  private initializeForm() {
    const userProfileDetails = this.userProfileDetails || {
      firstName: '',
      lastName: '',
      userName: '',
      phoneCountry: '',
      phone: '',
      phoneExtension: '',
      languageCode: '',
      timeZoneCode: '',
      dateType: '',
    };

    this.userProfileForm = this.formBuilder.group(
      {
        firstName: [userProfileDetails.firstName, [Validators.required]],
        lastName: [userProfileDetails.lastName, [Validators.required]],
        userName: [
          userProfileDetails.userName,
          [Validators.required, Validators.email],
        ],
        password: [
          '',
          Validators.compose([
            this.validationService.patternValidator('number'),
            this.validationService.patternValidator('upperCase'),
            this.validationService.patternValidator('lowerCase'),
            this.validationService.specialCharacterValidator(),
            this.validationService.minimumLength(8),
          ]),
        ],
        confirmPassword: [''],
        languageCode: [userProfileDetails.languageCode, [Validators.required]],
        timeZoneCode: [userProfileDetails.timeZoneCode, [Validators.required]],
        dateType: [userProfileDetails.dateType, [Validators.required]],
      },
      {
        validator: this.validationService.MustMatch(
          'password',
          'confirmPassword'
        ),
      }
    );
    this.phoneForm = this.formBuilder.group(
      {
        phoneCountry: [userProfileDetails.phoneCountry],
        phoneExtension: [
          userProfileDetails.phoneExtension,
          Validators.compose([
            Validators.pattern('^[0-9]*$'),
            Validators.maxLength(10),
          ]),
        ],
        phone: [
          userProfileDetails.phone,
          this.validationService.validatePhoneNumber(parsePhoneNumber),
        ],
      },
      {
        validators: this.validationService.validatePhoneForm(
          'phone',
          'phoneExtension'
        ),
      }
    );
  }

  public passwordFieldEmptyCheck(passwordStatus) {
    this.passwordEmpty = passwordStatus ? false : true;
  }

  /*Handling Save Button Click */
  public handleSubmit() {
    try {
      this.updatedProfileInfo.emit({
        ...this.userProfileForm.value,
        ...this.phoneForm.value,
        phoneCountry: i18nIsoCountries.alpha2ToAlpha3(
          this.phoneForm.controls.phoneCountry.value
        ),
        phone: parsePhoneNumber(
          this.phoneForm.controls.phone.value,
          this.phoneForm.controls.phoneCountry.value as CountryCode
        ).format('E.164'),
      });
    } catch {
      this.updatedProfileInfo.emit({
        ...this.userProfileForm.value,
        ...this.phoneForm.value,
        phoneCountry: this.account ? this.account.details.country : '',
        phone: '',
        phoneExtension: '',
      });
    }
  }

  // bound to Phone control's input event
  public formatPhoneNumber() {
    try {
      if (this.phoneForm.controls.phone.value.length < 1) {
        this.phoneForm.controls.phoneCountry.setValue(
          i18nIsoCountries.alpha3ToAlpha2(
            this.account ? this.account.details.country : ''
          )
        );
      }
      this.phoneForm.controls.phone.setValue(
        parsePhoneNumber(
          this.phoneForm.controls.phone.value,
          this.phoneForm.controls.phoneCountry.value
        ).formatInternational()
      );
    } catch {
      /*empty*/
    }
  }

  // bound to Countries Dropdown control's onChange event
  public resetPhoneNumber() {
    this.phoneForm.controls.phone.setValue('');
  }

  // helper function for userProfileDetailsSetter
  private formatCountryCodeForDropdown() {
    try {
      this.phoneForm.controls.phoneCountry.setValue(
        i18nIsoCountries.alpha3ToAlpha2(
          this.userProfileDetails.phoneCountry.trim()
        )
      );
    } catch {
      this.phoneForm.controls.phoneCountry.setValue(
        i18nIsoCountries.alpha3ToAlpha2(
          this.account ? this.account.details.country : ''
        )
      );
    }
  }

  // helper function for userProfileDetailsSetter
  private formatPhoneToInternational() {
    try {
      this.phoneNumber.input(this.userProfileDetails.phone);

      this.phoneForm.controls.phone.setValue(
        this.phoneNumber.getNumber().formatInternational()
      );
    } catch {
      this.phoneForm.controls.phone.setValue(
        this.userProfileDetails ? this.userProfileDetails.phone : ''
      );
    }
  }
}
