import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthenticateService, UserAuthRequest } from '@lbmx/phoenix-lib-auth';
import { LoggerService } from '@lbmx/phoenix-lib-core';
import { SpinnerService } from '@lbmx/phoenix-lib-utils';
import { ConfigProvider } from 'src/app/provider/config-provider';

@Component({
  selector: 'app-user-login-component',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  //#region Properties
  private navigateToUrl: string;
  private rememberMe: boolean;
  private isCredentialInvalid = false; // used in the html component

  public loginForm: FormGroup = this.formBuilder.group({
    username: [
      '',
      [Validators.required, Validators.email, Validators.maxLength(100)],
    ],
    password: ['', [Validators.required]],
    checked: [''],
  });
  //#endregion
  constructor(
    private router: Router,
    private loggerSrv: LoggerService,
    private authSrv: AuthenticateService,
    private spinnerSrv: SpinnerService,
    private formBuilder: FormBuilder,
    private configProvider: ConfigProvider,
    private route: ActivatedRoute
  ) {}

  public async ngOnInit() {
    this.authSrv.logOut(true);
    this.navigateToUrl =
      this.route.snapshot.queryParams['nextUrl'] || '/default';
    const cookieStatus = await this.authSrv.getCookieRememberMe().toPromise();
    this.rememberMe = cookieStatus.rememberMeStatus;

    if (this.rememberMe) {
      this.loginForm.controls['checked'].setValue(true);

      const cookieUser = await this.authSrv.getCookieUserName().toPromise();
      this.loginForm.controls['username'].setValue(cookieUser.userName);
    }

    if (this.rememberMe == null) {
      this.rememberMe =
        this.loginForm.value['checked'].value === null
          ? false
          : this.loginForm.value['checked'];
    }

    this.spinnerSrv.hide();
    this.loginForm.get('password').valueChanges.subscribe((password) => {
      if (password !== this.loginForm.value.password) {
        this.isCredentialInvalid = false;
      }
    });
  }

  //#region Methods

  public authenticate(): void {
    const { uriSecurity } = this.configProvider.AppSetting;
    this.spinnerSrv.on();
    this.authSrv
      .fetchTokenUsingPasswordFlowAndLoadUserProfileToState(
        this.buildLoginRequest(),
        uriSecurity
      )
      .then(
        (res) => {
          if (res != null) {
            this.loggerSrv.info(
              'AuthService.authenticate',
              'User was authenticated [response]',
              JSON.stringify(res)
            );

            this.router.navigate([this.navigateToUrl]);
          }
        },
        (err) => {
          if (err.status === 401) {
            // 401 unauthorized
            this.isCredentialInvalid = true;
          }
        }
      )
      .finally(() => this.spinnerSrv.off());
  }

  public checkBoxStatus(): void {
    this.rememberMe = this.loginForm.value['checked'];
  }

  //#region Helpers

  private buildLoginRequest(): UserAuthRequest {
    const request: UserAuthRequest = new UserAuthRequest();
    request.username = this.loginForm.value['username'].trim();
    request.password = this.loginForm.value['password'].trim();
    request.rememberMe = this.rememberMe;

    return request;
  }

  //#endregion
}
