import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';

import { actions, selectors } from '@lbmx/app-state';
import { UserProfile } from '@lbmx/types';

import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';

import { Observable, of } from 'rxjs';
import { catchError, exhaustMap, switchMap } from 'rxjs/operators';

import { routePermissions } from '../../features/secure/main-navigation-container/permissions';
import { ConfigProvider } from '../../provider/config-provider';
import { isAuthorized } from './utils';

@Injectable()
export class AuthGuardService implements CanActivateChild {
  constructor(
    private actions$: Actions,
    private configPrv: ConfigProvider,
    private router: Router,
    private store: Store
  ) {}

  public canActivateChild(
    _: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    return this.store.pipe(
      select(selectors.userProfile),
      switchMap((userProfile: UserProfile) => {
        return isAuthorized(userProfile.toolKeys, routePermissions, state.url)
          ? of(true)
          : this.fetchUserProfile(userProfile, state.url);
      }),
      catchError(() => of(this.router.parseUrl(`/login`)))
    );
  }

  private fetchUserProfile(
    userProfile: UserProfile,
    url: string
  ): Observable<boolean | UrlTree> {
    if (userProfile.toolKeys.length === 0) {
      const { uriSecurity } = this.configPrv.AppSetting;
      this.store.dispatch(
        actions.loadUserProfile({
          endPoints: uriSecurity,
        })
      );
    } else {
      return of(this.router.parseUrl('/home'));
    }

    return this.actions$.pipe(
      ofType(actions.loadUserProfileSuccess, actions.loadUserProfileFailure),
      exhaustMap(
        (action): Observable<any> => {
          return of(
            action.type === '[App] Load UserProfile Failure'
              ? this.router.parseUrl(`/login?nextUrl=${url}`)
              : isAuthorized(
                  action.userProfile.toolKeys,
                  routePermissions,
                  url
                ) || this.router.parseUrl(`/home`)
          );
        }
      )
    );
  }
}
