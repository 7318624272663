import { Inject, Injectable, LOCALE_ID } from '@angular/core';

import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { AppSetting } from '@lbmx/types';
import {
  IResetLinkValidationResponse,
  PasswordRecoveryRequest,
  ResetPasswordRequest,
  ResetToken,
} from 'src/app/dtos/forgotPassword/forgotPassword.dto';
import { ConfigProvider } from 'src/app/provider/config-provider';

import { BaseAPIService, LoggerService } from '@lbmx/phoenix-lib-core';

@Injectable({
  providedIn: 'root',
})
export class PasswordResetService {
  constructor(
    private configPrv: ConfigProvider,
    private baseAPISrv: BaseAPIService,
    private loggerSrv: LoggerService
  ) {}

  //#region Methods
  public getPasswordRecovery(user: string) {
    const appSettings: AppSetting = this.configPrv.AppSetting;
    this.baseAPISrv.set(appSettings.uriSecurity.recoverPassword);

    const request: PasswordRecoveryRequest = {
      username: user,
    };

    this.baseAPISrv
      .post<string>(request)
      .pipe(take(1))
      .subscribe(
        (response: any) => {
          this.loggerSrv.info(
            'Sent password reset link',
            'PasswordResetService.getPasswordRecovery',
            response
          );
        },
        (err: any) => {
          this.loggerSrv.error(
            'Error sending password reset link',
            'PasswordResetService.getPasswordRecovery',
            err
          );
        }
      );
  }

  public validatePasswordResetLink(
    token: ResetToken
  ): Observable<IResetLinkValidationResponse> {
    const appSettings: AppSetting = this.configPrv.AppSetting;
    this.baseAPISrv.set(appSettings.uriSecurity.validateResetPasswordLink);
    return this.baseAPISrv.get<IResetLinkValidationResponse>(token);
  }

  public resetPassword(resetRequest: ResetPasswordRequest) {
    const appSettings: AppSetting = this.configPrv.AppSetting;
    this.baseAPISrv.set(appSettings.uriSecurity.resetPassword);
    this.baseAPISrv
      .post<string>(resetRequest)
      .pipe(take(1))
      .subscribe(
        (response: any) => {
          this.loggerSrv.info(
            'Updated password',
            'PasswordResetService.resetPassword',
            response
          );
        },
        (err: any) => {
          this.loggerSrv.error(
            'Failed to update password',
            'PasswordResetService.resetPassword',
            err
          );
        }
      );
  }

  //#endregion
}
