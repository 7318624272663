import { Location } from '@angular/common';
import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';

import { LoggerService } from '@lbmx/phoenix-lib-core';
import { ValidationService } from '@lbmx/phoenix-lib-utils';

import { TranslocoService } from '@ngneat/transloco';
import {
  ResetPasswordRequest,
  ResetToken,
} from 'src/app/dtos/forgotPassword/forgotPassword.dto';
import { PasswordResetService } from 'src/app/services/resetPassword/passwordReset.service';

@Component({
  selector: 'app-reset-password-component',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit, AfterViewChecked {
  public passwordEmpty = true;
  public message = {
    confirmPassword: () =>
      this.translocoService.translate('COMMON.PASSWORD_MUST_MATCH'),
  };
  public resetPasswordForm: FormGroup = this.formBuilder.group(
    {
      newPassword: [
        '',
        Validators.compose([
          Validators.required,
          this.validationService.patternValidator('number'),
          this.validationService.patternValidator('upperCase'),
          this.validationService.patternValidator('lowerCase'),
          this.validationService.specialCharacterValidator(),
          this.validationService.minimumLength(8),
        ]),
      ],
      confirmPassword: ['', [Validators.required]],
    },
    {
      validator: this.validationService.MustMatch(
        'newPassword',
        'confirmPassword'
      ),
    }
  );

  public validToken = false;
  public passwordReset = false;
  public passwordMatch = true;
  public resetToken = '';
  public userKey = 0;
  public isDefault = true;

  constructor(
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private resetPswdSrv: PasswordResetService,
    private formBuilder: FormBuilder,
    private loggerSrv: LoggerService,
    private validationService: ValidationService,
    private changeDetector: ChangeDetectorRef,
    private translocoService: TranslocoService
  ) {}

  public ngOnInit() {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.resetToken = params.token;
    });
    this.validateToken();
    this.location.replaceState('resetPassword');
  }
  public ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }
  //#region Methods

  public validateToken(): void {
    this.resetPswdSrv
      .validatePasswordResetLink(this.buildValidateTokenRequest())
      .pipe(take(1))
      .subscribe(
        (res) => {
          this.loggerSrv.info(
            'resetTokenResponse',
            'PasswordResetService.validatePasswordResetLink',
            JSON.stringify(res)
          );
          this.userKey = res.userKey;
          this.validToken = true;
        },
        (err) => {
          this.loggerSrv.error(
            'Error validating password reset token',
            'PasswordResetService.validatePasswordResetLink',
            err
          );
          this.validToken = false;
          this.isDefault = false;
        }
      );
  }

  public resetPassword(): void {
    if (
      this.resetPasswordForm.value['newPassword'] ===
      this.resetPasswordForm.value['confirmPassword']
    ) {
      this.resetPswdSrv.resetPassword(this.buildResetPasswordRequest());
      this.passwordReset = true;
    } else {
      this.passwordMatch = false;
    }
  }

  //#endregion

  //#region Helpers

  private buildValidateTokenRequest(): ResetToken {
    const request: ResetToken = new ResetToken();

    request.token = this.resetToken;

    return request;
  }

  private buildResetPasswordRequest(): ResetPasswordRequest {
    const request: ResetPasswordRequest = new ResetPasswordRequest();

    request.userKey = this.userKey;
    request.newPassword = this.resetPasswordForm.value['newPassword'];
    request.token = this.resetToken;

    return request;
  }

  //#endregion

  public passwordFieldEmptyCheck(passwordStatus) {
    this.passwordEmpty = passwordStatus ? false : true;
  }
}
