import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { LoggerService } from '@lbmx/phoenix-lib-core';
import { ToastrNotificationService } from '@lbmx/phoenix-lib-utils';

import { selectors } from '@lbmx/app-state';
import { NavItem } from '@lbmx/types';

import { translate } from '@ngneat/transloco';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { routePermissions } from './permissions';

@Component({
  selector: 'app-main-navigation-container',
  templateUrl: './main-navigation-container.component.html',
  styleUrls: ['./main-navigation-container.component.scss'],
})
export class MainNavigationContainerComponent implements OnInit {
  public allowedModules$: Observable<{ [key: string]: boolean }>;
  public navigationItems$: Observable<NavItem[]>;

  constructor(
    private logger: LoggerService,
    private router: Router,
    private store: Store,
    private toastrNotificationService: ToastrNotificationService
  ) {}

  public ngOnInit() {
    this.allowedModules$ = this.getAllowedModules();
    this.navigationItems$ = this.store.pipe(select(selectors.navItems));
  }

  private getAllowedModules(): any {
    return this.store.pipe(
      select(selectors.isAuthorized, routePermissions),
      switchMap((permissionsList) => {
        const items = {
          ...permissionsList,
          '/PlaygroundModule': true,
          '/home': true,
        };
        return of(items);
      })
    );
  }

  private logMessage(path: string): void {
    this.logger.info(
      'ProjectSelectorComponent',
      'Loaded mini-app into main container',
      `Module loaded: ${path.split('/').pop()}`
    );
  }

  private navigateToTheCurrentModule(path: string): void {
    this.router.navigate([path]);
  }

  private showToasterNotification(path: string): void {
    this.toastrNotificationService.successNotify(
      `Loaded project to ${translate(path.split('/').pop())}`
    );
  }

  public valueChange(path: string): void {
    this.navigateToTheCurrentModule(path);
    this.showToasterNotification(path);
    this.logMessage(path);
  }
}
