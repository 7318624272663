import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class EnvService {
  public BaseUrl = 'http://localhost:4200';
  public Name = 'debug';
  public Prod = 'false';

  // tslint:disable-next-line: no-empty
  constructor() { }
}
