import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Translation, TRANSLOCO_LOADER, TranslocoLoader } from '@ngneat/transloco';
import {EnvService} from '../app/services/env.service';

@Injectable()
export class HttpLoader implements TranslocoLoader {
  constructor(private http: HttpClient, private envSevice: EnvService) { }

 public getTranslation(langPath: string) {
    return this.http.get<Translation>(`${this.envSevice.BaseUrl}/assets/i18n/${langPath}.json`);
  }
}

export const httpLoader = { provide: TRANSLOCO_LOADER, useClass: HttpLoader };
