export default [
  {
    label: 'PIM',
    routerLink: '/PIMModule',
    routerLinkActiveOptions: {
      exact: false,
    },
    disabled: true,
    styleClass: 'uppercase',
    items: [
      {
        label: 'CONTROL_CENTRE',
        items: [
          {
            label: 'CONTROL_CENTRE',
            routerLink: '/PIMModule/analytics',
          },
        ],
      },
      {
        label: 'PRODUCTS',
        routerLink: '/PIMModule/products',
        disabled: true,
        routerLinkActiveOptions: {
          exact: false,
        },
        items: [
          {
            label: 'APPROVED_PRODUCT',
            routerLink: '/PIMModule/products/product-search',
          },
          {
            label: 'PENDING_PRODUCT',
            routerLink: '/PIMModule/products/pending-products',
          },
          {
            label: 'PRODUCT_FIELD',
            routerLink: '/PIMModule/products/config-product-field',
          },
          {
            label: 'UPDATE_PROFILE',
            routerLink: '/PIMModule/products/supplier-update-profile',
          },
        ],
      },
      {
        label: 'IMPORT',
        items: [
          {
            label: 'IMPORT_DATA',
            routerLink: '/PIMModule/imports/import-products',
          },
          {
            label: 'IMPORT_ASSETS',
            routerLink: '/PIMModule/imports/import-assets',
          },
        ],
        disabled: true,
      },
      {
        label: 'EXPORT',
        disabled: true,
        items: [
          {
            label: 'VIEW_EXPORTS',
            routerLink: '/PIMModule/exports',
          },
        ],
      },
    ],
  },
  {
    label: 'PARTNERS',
    routerLink: '/MarketplacePartnersModule',
    routerLinkActiveOptions: {
      exact: false,
    },
    disabled: true,
    styleClass: 'uppercase',
    items: [
      {
        items: [
          {
            label: 'COMPANY',
            routerLink: '/MarketplacePartnersModule/company',
            disabled: true,
          },
          {
            label: 'GROUP',
            routerLink: '/MarketplacePartnersModule/group',
            disabled: true,
          },
          {
            label: 'SUPPLIER',
            routerLink: '/MarketplacePartnersModule/supplier',
            disabled: true,
          },
          {
            label: 'MEMBER',
            routerLink: '/MarketplacePartnersModule/member',
            disabled: true,
          },
          {
            label: 'REQUESTS',
            routerLink: '/MarketplacePartnersModule/requests',
            disabled: true,
          },
        ],
      },
    ],
  },
  {
    icon: 'pi pi-fw pi-cog',
    routerLink: '/AdministrationModule',
    routerLinkActiveOptions: {
      exact: false,
    },
    disabled: true,
    styleClass: 'uppercase',
    items: [],
  },
  {
    icon: 'pi pi-fw pi-user',
    routerLinkActiveOptions: {
      exact: false,
    },
    disabled: true,
    styleClass: 'uppercase',
    items: [
      {
        items: [
          {
            label: 'PROFILE',
            routerLink: '/userProfile',
            disabled: true,
          },
          {
            label: 'LOG_OUT',
            routerLink: '/login',
            disabled: true,
          },
        ],
      },
    ],
  },
];
