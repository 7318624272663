import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-main-footer',
  templateUrl: './main-footer.component.html',
  styleUrls: ['./main-footer.component.scss'],
})
export class MainFooterComponent implements OnInit {
  //region Properties

  public lbmxLogoImage = './assets/images/lbmx_logo.png';
  public thisYear = new Date().getFullYear();

  //#endregion

  constructor() {
    /** empty block */
  }

  public ngOnInit() {
    /** empty block */
  }
}
