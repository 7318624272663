import { Component } from '@angular/core';
import { NgxLoggerLevel } from 'ngx-logger';
import { filter } from 'rxjs/operators';

import { AppSetting } from '@lbmx/types';
import { ConfigProvider } from './provider/config-provider';

import { AdminConfig, AdministrationService } from '@lbmx/administration';
import { MarketplacePartnersService } from '@lbmx/marketplace-partners';
import { MarketplacePartnersConfig } from '@lbmx/marketplace-partners/lib/marketplace-partners.config';
import { AuthConfig, AuthenticateService } from '@lbmx/phoenix-lib-auth';
import { LoggerService } from '@lbmx/phoenix-lib-core';
import { PimConfig, PimService } from '@lbmx/phoenix-pim';

import { selectors } from '@lbmx/app-state';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(
    private configPrv: ConfigProvider,
    private authSrv: AuthenticateService,
    private adminSrv: AdministrationService,
    private loggerSrv: LoggerService,
    private pimSrv: PimService,
    private marketplaceSrv: MarketplacePartnersService
  ) {
    this.configAuth();
    this.configAdmin();
    this.configPim();
    this.configMarketplacePartners();
    this.configLogger();
  }

  private configLogger() {
    this.loggerSrv.setLoggerConfig(this.getUriLoggerConfig());
  }

  private getUriLoggerConfig() {
    const appSettings: AppSetting = this.configPrv.AppSetting;

    const loggerConfig = this.loggerSrv.getLoggerConfig();

    loggerConfig.level = NgxLoggerLevel.TRACE;
    loggerConfig.serverLoggingUrl = appSettings.uriLogging.logging;
    loggerConfig.serverLogLevel = NgxLoggerLevel.TRACE;

    return loggerConfig;
  }

  private configAuth() {
    this.authSrv.configure(this.getAuthConfig());

    this.authSrv.events
      .pipe(filter((e) => e.type === 'token_received'))
      .subscribe((e) => {
        this.loggerSrv.info(
          'AppComponent.configAuth',
          'User session has started: token_received'
        );
      });

    this.configPim();
  }

  private getAuthConfig(): AuthConfig {
    const appSettings: AppSetting = this.configPrv.AppSetting;

    const authConfig: AuthConfig = {
      // Url of the Identity Provider
      // issuer: 'https://steyer-identity-server.azurewebsites.net/identity',

      // URL of the SPA to redirect the user to after login
      // redirectUri: window.location.origin + '/index.html',

      // URL of the SPA to redirect the user after silent refresh
      // silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html',

      tokenEndpoint: appSettings.uriSecurity.authenticate,

      userinfoEndpoint: appSettings.uriSecurity.getProfile,

      refreshTokenEndpoint: appSettings.uriSecurity.slidingSession,

      logOutEndPoint: appSettings.uriSecurity.logOut,

      rememberMeLoadEndPoint: appSettings.uriSecurity.rememberMeLoad,

      getCookieRememberMeEndPoint: appSettings.uriSecurity.getCookieRememberMe,

      // The SPA's id. The SPA is registerd with this id at the auth-server
      // clientId: 'spa-phonenix',

      // set the scope for the permissions the client should request
      // The first three are defined by OIDC. The 4th is a usecase-specific one
      // scope: 'openid profile email voucher',
    };
    return authConfig;
  }

  private configPim() {
    this.pimSrv.setConfig(this.getPimConfig());
  }

  private getPimConfig(): PimConfig {
    const appSettings: AppSetting = this.configPrv.AppSetting;
    const pimConfig: PimConfig = {
      getAllProductFieldsEndpoint: appSettings.uriProduct.getAllProductFields,
      getFieldCategoriesEndpoint: appSettings.uriProduct.getFieldCategories,
      assetsEndPoint: appSettings.uriDocServer.assets,
      uploadAssetsEndPoint: appSettings.uriDocServer.uploadAssets,
      uploadSupplierAssetsEndPoint:
        appSettings.uriDocServer.uploadSupplierAssets,
      importFileEndPoint: appSettings.uriDocServer.imports,
      exportFileEndPoint: appSettings.uriDocServer.exports,
      getIncludedFieldsEndpoint: appSettings.uriProduct.getIncludedFields,
      getApprovedProductsEndpoint: appSettings.uriProduct.getApprovedProducts,
      searchApprovedProductsEndpoint:
        appSettings.uriProduct.searchApprovedProducts,
      getProductDetailsEndpoint: appSettings.uriProduct.productDetails,
      importLayoutsEndPoint: appSettings.uriImport.importLayouts,
      importEndPoint: appSettings.uriImport.import,
      getFileHeadersEndPoint: appSettings.uriImport.getFileHeaders,
      getFieldAllowedValuesEndpoint:
        appSettings.uriProduct.getFieldAllowedValues,
      getCountriesEndpoint: appSettings.uriProduct.getCountries,
      getCurrenciesEndpoint: appSettings.uriProduct.getCurrencies,
      getAccountRelationshipEndpoint:
        appSettings.uriMarketplacePartners.getAccountRelationship,
      exportProductsEndpoint: appSettings.uriProduct.exportProducts,
      searchExportsEndpoint: appSettings.uriDocServer.searchExports,
      upsertAssetsEndpoint: appSettings.uriProduct.upsertAssets,
      uriProduct: appSettings.uriProduct,
      searchPendingProductsEndpoint:
        appSettings.uriProduct.searchPendingProducts,
      approvePendingProductEndpoint: appSettings.uriProduct.approveProducts,
      rejectPendingProductEndpoint: appSettings.uriProduct.rejectProducts,
      getKpiEndPoint: appSettings.uriProduct.getKpi,
      getTermsAggregationEndPoint: appSettings.uriProduct.getTermsAggregation,
      getUsersFullNameEndPoint: appSettings.uriAdministration.getUserFullName,
    };
    return pimConfig;
  }
  private configAdmin() {
    this.adminSrv.setConfig(this.getAdminConfig());
  }

  private getAdminConfig(): AdminConfig {
    const appSettings: AppSetting = this.configPrv.AppSetting;
    const adminConfig: AdminConfig = {
      searchUserEndpoint: appSettings.uriAdministration.searchUser,
      addUserEndpoint: appSettings.uriAdministration.addUser,
      updateUserEndpoint: appSettings.uriAdministration.updateUser,
      listPermissionsEndpoint: appSettings.uriAdministration.listPermissions,
      deleteUserEndpoint: appSettings.uriAdministration.deleteUser,
      languageTypesEndpoint: appSettings.uriAdministration.languageTypes,
      timeZoneTypesEndpoint: appSettings.uriConfig.getTimeZoneTypes,
      dateFormatTypesEndpoint: appSettings.uriAdministration.dateFormatTypes,
      exportUsersEndpoint: appSettings.uriAdministration.exportUsers,
      getConfigEndpoint: appSettings.uriAdministration.getConfig,
      getAccountEndpoint: appSettings.uriAdministration.getAccount,
    };
    return adminConfig;
  }

  private configMarketplacePartners() {
    this.marketplaceSrv.setConfig(this.getMarketplacePartnersConfig());
  }

  private getMarketplacePartnersConfig(): MarketplacePartnersConfig {
    const appSettings: AppSetting = this.configPrv.AppSetting;
    const marketplacePartnersConfig: MarketplacePartnersConfig = {
      getAccountRelationshipEndpoint:
        appSettings.uriMarketplacePartners.getAccountRelationship,
      getAccountEndpoint: appSettings.uriMarketplacePartners.getAccount,
      getConfigEndpoint: appSettings.uriMarketplacePartners.getConfig,
      getLocationEndpoint: appSettings.uriMarketplacePartners.getLocation,
      getUserStateEndpoint: appSettings.uriUserState.getUserState,
      getFieldCategoriesEndpoint: appSettings.uriProduct.getFieldCategories,
      getAllProductFieldsEndpoint: appSettings.uriProduct.getAllProductFields,
      accountAssetsEndPoint: appSettings.uriDocServer.uploadAccountAssets,
      deleteAccountAssetsEndPoint: appSettings.uriDocServer.deleteAccountAssets,
      updateAccountEndpoint: appSettings.uriMarketplacePartners.updateAccount,
      deleteLogoAssetKeyEndpoint:
        appSettings.uriMarketplacePartners.deleteLogoAssetKey,
    };
    return marketplacePartnersConfig;
  }

  //#endregion
}
