import { Component, OnInit } from '@angular/core';
import { actions, selectors } from '@lbmx/app-state';
import { NavItem, UserProfile } from '@lbmx/types';
import { TranslocoService } from '@ngneat/transloco';
import { select, Store } from '@ngrx/store';
import { take, tap } from 'rxjs/operators';

import { ConfigProvider } from 'src/app/provider/config-provider';
import disabledNavItems from './nav-Items';
import { routePermissions } from './permissions';

@Component({
  selector: 'app-secure-layout-component',
  templateUrl: './secure.component.html',
})
export class SecureComponent implements OnInit {
  //#region Properties

  public loggedUserFullName = '';
  //#endregion

  constructor(
    private configPrv: ConfigProvider,
    private store: Store,
    private translocoService: TranslocoService
  ) {
    this.configStore();
  }

  public ngOnInit() {
    this.store
      .pipe(
        select(selectors.userProfile),
        tap((user: UserProfile) => {
          this.translocoService.setActiveLang(
            user.userInfo.LanguageCode ? user.userInfo.LanguageCode : 'en-ca'
          ); // when logging out, it will change to en-ca automatically
        })
      )
      .subscribe();

    this.store
      .pipe(
        select(selectors.authorizeRecursively, {
          permissionQuery: routePermissions,
          itemList: disabledNavItems,
          transformer: (item: NavItem, authorizedNavItems) =>
            Object.assign(
              {},
              {
                disabled: this.unauthorizedItem(authorizedNavItems, item),
              },
              this.unauthorizedItem(authorizedNavItems, item) && {
                routerLink: '',
              }
            ),
          recursiveProp: 'items',
        }),
        take(1)
      )
      .subscribe((navItems: NavItem[]) => {
        this.store.dispatch(
          actions.setNavItems({
            navItems,
          })
        );
      });
  }

  private configStore() {
    const { uriProduct, uriSecurity } = this.configPrv.AppSetting;
    this.store.dispatch(actions.loadUserProfile({ endPoints: uriSecurity }));
    this.store.dispatch(actions.loadCategories({ endPoints: uriProduct }));
    this.store.dispatch(actions.loadProductFields({ endPoints: uriProduct }));
  }

  private urlToPermissionKey(url: string): string {
    const paths = url.split('/').filter((x) => x !== '');
    return paths.filter((path) => isNaN(parseInt(path, 10))).join('_');
  }

  private unauthorizedItem(
    authorizedNavItems: { [key: string]: boolean },
    item: NavItem
  ) {
    return item.routerLink
      ? !authorizedNavItems[this.urlToPermissionKey(item.routerLink)]
      : false;
  }
}
